<template>
  <!-- MODAL COPY BOOKING -->
  <b-modal
    id="copy-booking"
    ref="copy-booking"
    size="md"
    :title="$t('table.copyBooking')"
    :ok-disabled="$v.$invalid || isBusy"
    :ok-title="$t('table.copyBooking')"
    :cancel-title="$t('table.cancel')"
    @ok.prevent="copyBooking"
    @hidden="clearFields"
    @show="setTimeByChannel"
  >
    <form v-if="currentMediaPlan" class="d-flex flex-wrap w-100 justify-content-between" @submit.stop.prevent="checkIfValidThenEnter">
      <input type="submit" value="Submit" class="hidden-submit" />
      <b-form-group style="width: 49%" :label="$t('table.start_at')" label-for="datepicker-buttons-from">
        <datepicker-wrapper
          id="datepicker-buttons-from"
          v-model="dateFrom"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :min="currentMediaPlan.date_from"
          :max="currentMediaPlan.date_to"
          required
        />
      </b-form-group>
      <b-form-group style="width: 49%" :label="$t('table.end_at')" label-for="datepicker-buttons-to">
        <datepicker-wrapper
          id="datepicker-buttons-to"
          v-model="dateTo"
          required
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :min="getMaxDate(dateFrom, currentMediaPlan.date_from)"
          :max="currentMediaPlan.date_to"
        />
      </b-form-group>

      <b-form-group :label="$t('table.start_at')" label-for="input-6" style="width: 49%">
        <BroadcastTimePicker id="input-6" v-model="timeFrom" :min-time="minTime" :max-time="maxTime" required :interval-mode="true" />
      </b-form-group>
      <b-form-group :label="$t('table.end_at')" label-for="input-7" style="width: 49%">
        <BroadcastTimePicker id="input-7" v-model="timeTo" :min-time="minTime" :max-time="maxTime" required :interval-mode="true" />
      </b-form-group>

      <label class="w-100 font-weight-bold"> {{ $t('booking.copyTo') }}:</label>
      <b-form-group style="width: 49%" :label="$t('table.start_at')" label-for="datepicker-buttons-copy-from">
        <datepicker-wrapper
          id="datepicker-buttons-copy-from"
          v-model="dateCopyFrom"
          required
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :date-disabled-fn="datesToDisabled"
          :min="currentMediaPlan.date_from"
          :max="currentMediaPlan.date_to"
        />
      </b-form-group>
      <b-form-group style="width: 49%" :label="$t('table.end_at')" label-for="datepicker-buttons-copy-to">
        <datepicker-wrapper
          id="datepicker-buttons-copy-to"
          v-model="dateCopyTo"
          required
          disabled
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :min="getMaxDate(dateCopyFrom, currentMediaPlan.date_from)"
          :max="currentMediaPlan.date_to"
        />
      </b-form-group>
    </form>
  </b-modal>
  <!-- MODAL COPY BOOKING -->
</template>

<script>
import { mapGetters } from 'vuex';
import { required, not, sameAs } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import setTimeOfSpot from '@/mixins/setTimeOfSpot';

function isNotPeriodToInPeriodFrom() {
  //check for not overlapping periods
  const dateFromStart = new Date(this.dateFrom);
  const dateFromEnd = new Date(this.dateTo);
  const dateToStart = new Date(this.dateCopyFrom);
  const dateToEnd = new Date(this.dateCopyTo);
  return !((dateToStart <= dateFromStart && dateToEnd >= dateFromStart) || (dateToStart >= dateFromStart && dateToStart <= dateFromEnd));
}

function isPeriodsEqual() {
  if (!(this.dateFrom && this.dateTo && this.dateCopyFrom && this.dateCopyTo)) return false;
  return new Date(this.dateTo) - new Date(this.dateFrom) === new Date(this.dateCopyTo) - new Date(this.dateCopyFrom);
}

export default {
  name: 'ModalCopyBooking',
  components: { DatepickerWrapper, BroadcastTimePicker },
  mixins: [getMinOrMaxDate, setTimeOfSpot],
  props: {
    currentMediaPlan: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      dateCopyFrom: '',
      isBusy: false,
    };
  },
  validations: {
    dateFrom: { required, notSameAsDateTo: not(sameAs('dateCopyFrom')), isPeriodsEqual },
    dateTo: { required },
    timeFrom: { required },
    timeTo: { required },
    dateCopyFrom: { required, isNotPeriodToInPeriodFrom },
    dateCopyTo: { required },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),

    dateCopyTo() {
      if (!(this.dateFrom && this.dateTo && this.dateCopyFrom)) return '';
      const difference = new Date(this.dateTo).getTime() - new Date(this.dateFrom).getTime();
      const totalDays = Math.ceil(difference / (1000 * 3600 * 24)); // get period days count
      if (totalDays < 0) return ''; // for reversed incorrect period
      const dateToEnd = new Date(this.dateCopyFrom);
      dateToEnd.setDate(dateToEnd.getDate() + totalDays);
      // check for end date in MP period range
      if (dateToEnd <= new Date(this.currentMediaPlan.date_to)) return dateToEnd.toISOString().slice(0, 10);
      else return '';
    },
  },
  methods: {
    clearFields() {
      this.dateFrom = '';
      this.dateTo = '';
      this.timeFrom = this.minTime;
      this.timeTo = this.maxTime;
      this.dateCopyFrom = '';
    },

    datesToDisabled(ymd, date) {
      // disable selected period From
      const ymdDate = new Date(ymd); // use converted to avoid timezone shifts
      const dateFrom = new Date(this.dateFrom);
      const dateFromEnd = new Date(this.dateTo);
      return dateFrom <= ymdDate && ymdDate <= dateFromEnd;
    },

    hideModalCopyBooking() {
      this.$bvModal.hide('copy-booking');
    },

    async copyBooking() {
      this.isBusy = true;
      const id = this.currentMediaPlan.channel_id;
      const formData = {
        date_from_start_at: this.dateFrom,
        date_from_end_at: this.dateTo,
        start_interval: this.timeFrom,
        end_interval: this.timeTo,
        date_to_start_at: this.dateCopyFrom,
        date_to_end_at: this.dateCopyTo,
        mediaplan_id: this.currentMediaPlan.id,
      };
      await this.$store.dispatch('POST_COPY_BOOKING', {
        id,
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.copyBooking'),
            text: this.$i18n.t('alert.found') + ': ' + res.data.data.total + '. ' + this.$i18n.t('alert.copied') + ': ' + res.data.data.copied,
          });
          this.hideModalCopyBooking();
          this.$emit('updateData');
          if (this.$route?.name === 'booking') this.$emit('updateWgrp');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },
    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.copyBooking();
    },
  },
};
</script>
